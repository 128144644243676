import React from "react";
import './card.css';

function Cards({ data }) {
  return (
    <div className="card-container">
      {data.map((card, index) => (
        <div className="card-layout" key={card.id}>
          <div className="row row-big-card">
            <div className="cont">
              <a href="#" className="card-link">
                <div className="card-img">
                  <div style={{ backgroundImage: `url(${card.imgUrl})` }}></div>
                </div>
                <div className="card-inner">
                  <h3>{card.title}</h3>
                  <h4>{card.subtitle}</h4>
                  <div className="inner-text">
                    <p>{card.description}</p>
                  </div>
                  <span className="cta-card">Read on</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Cards;
